import React from "react";

import Scienceday1 from "../../../assets/Gallery/Thumbnails/science1.jpeg";

import Scienceday3 from "../../../assets/Gallery/Thumbnails/science3.jpeg";
import Scienceday4 from "../../../assets/Gallery/Thumbnails/science4.jpeg";
import Scienceday5 from "../../../assets/Gallery/Thumbnails/science5.jpeg";
import Scienceday6 from "../../../assets/Gallery/Thumbnails/science6.jpeg";
import Scienceday8 from "../../../assets/Gallery/Thumbnails/science8.png";


import { makeStyles } from '@material-ui/core/styles';
import Heading from "../../../components/Heading/Heading";
/*const useStyles = makeStyles({
  gallery: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
    gap: '10px',
    padding: '10px',
  },
  imageContainer: {
    position: 'relative',
    width: '100%',
    paddingBottom: '75%', // Aspect ratio 4:3
    overflow: 'hidden',
    '& img': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
});*/
const useStyles = makeStyles({
  gallery: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px',
    padding: '10px',
  },
  imageContainer: {
    position: 'relative',
    width: 'calc(33.333% - 10px)',
    height: '225px',
    overflow: 'hidden',
    '& img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  },
});



const images = [

  { src: Scienceday1, alt: "Image 1" },
 
  { src: Scienceday3, alt: "Image 3" },
  { src: Scienceday4, alt: "Image 4" },
  { src: Scienceday5, alt: "Image 5" },
  { src: Scienceday6, alt: "Image 6" },
  { src: Scienceday8, alt: "Image 8" },
  
];

export default function Scienceday() {
  const classes = useStyles();

  return (
    
    <div className="page">
    <Heading pageTitle={"National Science day"} />
      <div className={classes.gallery}>
      
        {images.map((image, index) => (
          <div key={index} className={classes.imageContainer}>
            <img src={image.src} alt={image.alt} />
          </div>
        ))}
      </div>
    </div>
    
  );
}
