import React from "react";
import { Link } from "react-router-dom";

import Heading from "../../../components/AdminHeading/Heading.js";

import announcements from "../../../assets/Admin/Dashboard/undraw_ideas_flow_cy7b.svg";
import education from "../../../assets/Admin/Dashboard/undraw_education_f8ru.svg";
import activities from "../../../assets/Admin/Dashboard/undraw_Organizing_projects_0p9a.svg";
import photos from "../../../assets/Admin/Dashboard/undraw_Photograph_re_up3b.svg";

import kids from "../../../assets/Admin/Dashboard/undraw_children_-4-rtb.svg";
import donations from "../../../assets/Admin/Dashboard/undraw_investing_re_bov7.svg";

import "./Dashboard.scss";

import { Helmet } from "react-helmet";

export default function Dashboard() {
  return (
    <div className="page dashboard-page">
      <Helmet>
        <title>DNALife | Dashboard</title>
        <meta name="author" content="Gaurav V, Divya" />
      </Helmet>
      <Heading elevation={15} pageTitle="Admin Dashboard" />
      <div className="all-admin-cards">
        <Link to="/admin/announcements" elevation={15} className="card">
          <img src={announcements} alt="announcements" />
          <div className="card-content">
            <h2>Announcements</h2>
            <p>
              Create a post to share all relevant updates and announcements
              related to DNAlife{" "}
            </p>
          </div>
        </Link>
        <Link to="/admin/activities" elevation={15} className="card">
          <img src={activities} alt="announcements" />
          <div className="card-content">
            <h2>Activities</h2>
            <p>
              Add a new educational tour, eco project, plantation drive or
              cleaning movement post here and share it with your audience
            </p>
          </div>
        </Link>
        <Link to="/admin/education" elevation={15} className="card">
          <img src={education} alt="announcements" />
          <div className="card-content">
            <h2>Education</h2>
            <p>
              Add a new training, internship or certificate course post here and
              share it with your audience
            </p>
          </div>
        </Link>
        <Link to="/admin/photos" elevation={15} className="card">
          <img src={photos} alt="announcements" />
          <div className="card-content">
            <h2>Gallery</h2>
            <p>
              Share links to photos posted on your socials like facebook and
              instagram or share a youtube video
            </p>
          </div>
        </Link>
        <Link to="/admin/kids-corner" elevation={15} className="card">
          <img src={kids} alt="announcements" />
          <div className="card-content">
            <h2>Kids Corner</h2>
            <p>
              Add content that helps foster children's relationship with nature.
            </p>
          </div>
        </Link>
        <a
          href="https://dashboard.razorpay.com/app/dashboard"
          elevation={15}
          target="_blank"
          className="card"
        >
          <img src={donations} alt="announcements" />
          <div className="card-content">
            <h2>Donations</h2>
            <p>
              View information about the donations made through Razorpay to the
              organisation.
            </p>
          </div>
        </a>
      </div>
    </div>
  );
}
