import React from "react";

import Heading from "../../components/Heading/Heading";

import AllPosts from "../../components/Posts/Type3.js";

import "./GP.scss";

import {Helmet} from "react-helmet";

export default function Posts() {
  return (
    <div className="new-gallery-page page">
      <Heading pageTitle={"Posts"} />
      <div style={{ marginTop: "1rem" }}></div>
      <AllPosts />
    </div>
  );
}
