import React from "react";

import Heading from "../../../components/Heading/Heading";

import Greener from "../../../assets/KidsCorner/greener-earth.jpeg";

import "./KidsCorner.scss";

export default function GreenerEarth() {
  return (
    <div className="page">
      <Heading pageTitle={"Kids Corner : Make a Greener Earth 🌍"} />
      <h4 className="head-liner">
        Why is it important make our Earth Greener? Watch the video below to
        find out.
      </h4>
      <div className="video">
        <iframe
          src={"https://www.youtube.com/embed/u3hha-N7V-I"}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <img className="greener-earth" src={Greener} alt="greener earth" />
    </div>
  );
}
