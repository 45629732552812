import React from "react";

import Heading from "../../../components/Heading/Heading";

import "./KidsCorner.scss";

export default function DoDont() {
  return (
    <div className="page">
      <Heading pageTitle={"Kids Corner : Do's ✅ and Dont's ❌"} />
      <div style={{ marginTop: "3rem" }}></div>
      <div className="comp">
        <h4>
          Here are some DOs and DON'Ts that you should follow to help make earth
          greener everyday.
        </h4>
        <table className="dodont">
          <tr className="table-row">
            <td className="day">
              <h5>DO</h5>
            </td>
            <td>
              <h5>DON'T</h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>Take the bus and carpool with friends!</h5>
            </td>
            <td>
              <h5>Take your car many places because it pollutes the air!</h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>Take out your trash and recyclables separately!</h5>
            </td>
            <td>
              <h5>Mix your recyclables together with your trash.</h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>Compost!</h5>
            </td>
            <td>
              <h5>Let your food scraps go to waste</h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>Drink out of reusable water bottles and coffee cups</h5>
            </td>
            <td>
              <h5>
                Waste plastic and paper by drinking out of throw away cups
              </h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>
                Turn off electronics and lights when you're not using them
              </h5>
            </td>
            <td>
              <h5>Waste electricity</h5>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}
