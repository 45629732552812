
// All.js
import React from 'react';
import Heading from "../../components/Heading/Heading";
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Card, CardMedia, CardContent, Typography } from '@material-ui/core';
import Cleaning from '../../assets/Gallery/Thumbnails/cleaning.jpg';
import Community from '../../assets/Gallery/Thumbnails/community.jpg';
import Felicitation from '../../assets/Gallery/Thumbnails/felicitation.jpg';
import Green from '../../assets/Gallery/Thumbnails/green.jpg';
import Planting from '../../assets/Gallery/Thumbnails/planting.jpg';
import Independence from '../../assets/Gallery/Thumbnails/independence.jpg';
import Ubiquitous from '../../assets/Gallery/Thumbnails/ubiquitous.jpg';
import Hoff from '../../assets/Gallery/Thumbnails/hoff.jpg';
import Anuganalu from '../../assets/Gallery/Thumbnails/anuganalu.jpg';

import Forest from '../../assets/Gallery/Thumbnails/forests.jpg'; // Import a representative image
import Scienceday from '../../assets/Gallery/Thumbnails/science.jpeg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: '10px',
  },
  cover: {
    width: 151,
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flex: '1 0 auto',
  },
}));

export default function All() {
  const classes = useStyles();
  const categories = [
    {
      name: "Cleaning Drives",
      thumbnail: Cleaning,
      url: "/gallery/all/cleaning-drives",
    },
    {
      name: "Community Interaction, Awareness and Education",
      thumbnail: Community,
      url: "/gallery/all/community-interaction",
    },
    {
      name: "Felicitation of Farmers and Volunteers at Anuganalu Village",
      thumbnail: Felicitation,
      url: "/gallery/all/felicitation",
    },
    {
      name: "Green Education Schools and Colleges",
      thumbnail: Green,
      url: "/gallery/all/green-education",
    },
    {
      name: "Planting Drives",
      thumbnail: Planting,
      url: "/gallery/all/planting-drives",
    },
    {
      name: "Independence Day - Distribution of certificates and plants to the forest farmers and volunteers",
      thumbnail: Independence,
      url: "/gallery/all/independence-day",
    },
    {
      name: "Planting Is Ubiquitous",
      thumbnail: Ubiquitous,
      url: "/gallery/all/planting-is-ubiquitous",
    },
    {
      name: "Principal Chief Conservator of Forests Head of Forest Force Visit to Anuganalu, Hassan",
      thumbnail: Hoff,
      url: "/gallery/all/visit-by-hoff",
    },
    {
      name: "Visit to the Revived Village - Anuganalu, Hassan",
      thumbnail: Anuganalu,
      url: "/gallery/all/visit-to-anuganalu",
    },
    {
      name: "Inauguration of School of Forest Farmers 07",
      thumbnail: Forest,
      url: "/gallery/all/forests",
    },
    {
      name: "National Science day",
      thumbnail: Scienceday,
      url: "/gallery/all/science",
    },
  ];

  return (
    <div className="page">
      <h1>All Photos</h1>
      <div className="all-gallery-photos">
        {categories.map((category, index) => (
          <Card key={index} className={classes.root}>
            <CardMedia
              className={classes.cover}
              image={category.thumbnail}
              title={category.name}
            />
            <div className={classes.details}>
              <CardContent className={classes.content}>
                <Typography variant="subtitle1">
                  <Link to={category.url} className="category-name">
                    {category.name}
                  </Link>
                </Typography>
              </CardContent>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
}
