import React from "react";
import { Link } from "react-router-dom";

import er from "../../assets/404/undraw_page_not_found_su7k.svg";

import { Helmet } from "react-helmet";

import "./404.scss";

export default function E404() {
  return (
    <div className="page nf">
      <Helmet>
        <title>DNALife | Page Not Found</title>
        <meta name="description" content="Error 404, Page Not Found" />
        <meta
          name="keywords"
          content={
            "DNALife, DNA Life, 404, error, page not found Malali Gowda, Gaurav V, Divya"
          }
        />
        <meta name="author" content="Gaurav V, Divya" />
      </Helmet>
      <img src={er} />
      <h1>Oops ! We couldn't find the page you were looking for</h1>
      <Link className="btn" to="/">
        Go Back Home
      </Link>
    </div>
  );
}
