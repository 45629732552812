import { Button, Paper } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";
import Heading from "../Heading/Heading";

import Empty from "../Empty/Empty";

import { db, storage, storageRef } from "../../firebase";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

export default function Post() {
  const classes = useStyles();
  const [reducerData, setData] = useState([]);

  let { type, id } = useParams();

  useEffect(() => {
    db.collection(type).onSnapshot((snapshot) => {
      let items = [];
      snapshot.forEach((doc) => {
        let data = {
          id: doc.id,
          title: doc.data().title,
          type: doc.data().type,
          subtitle: doc.data().subtitle,
          description: doc.data().description,
          filename: doc.data().filename,
          url: doc.data().url,
        };
        items.push(data);
      });
      setData(items);
    });
  }, []);

  let data = reducerData.find((post) => post.id == id);

  const [imgLoaded, setImgLoaded] = useState(false);
  const handleImageLoaded = () => {
    setImgLoaded(true);
  };

  const handleImageErrored = () => {
    setImgLoaded(false);
    console.log("Couldn't load image, Please refresh");
  };

  return (
    <div className="page">
      {data && <Heading pageTitle={data.title} />}
      {data ? (
        <div className="detailed-post">
          {data.description && (
            <div
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
          )}
          <div className="post-image-container">
            <img
              onLoad={handleImageLoaded}
              onError={handleImageErrored}
              src={data.url}
              alt="post-img"
            />
          </div>
          {imgLoaded ? (
            ""
          ) : (
            <div className={classes.root}>
              <LinearProgress />
            </div>
          )}
        </div>
      ) : (
        <div style={{ margin: "2rem", width: "100%" }}>
          <Empty message={"Fetching data from database ..."} />
        </div>
      )}
    </div>
  );
}
