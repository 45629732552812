import React from "react";

import Heading from "../../components/Heading/Heading";
import GalleryComponent from "../../components/Gallery/Gallery.js";

import "./GP.scss";

import {Helmet} from "react-helmet";

export default function Carousel() {
  return (
    <div className="new-gallery-page page">
      <Heading pageTitle={"Gallery"} />
      <div style={{ marginTop: "3rem" }}></div>
      <div className="comp">
        <GalleryComponent />
      </div>
    </div>
  );
}
