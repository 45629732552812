import React, { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import { useAuth } from "../../../contexts/AuthContext";
import { useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Helmet } from "react-helmet";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: "orange",
    color: "white",
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    color: "white",
  },
}));

export default function SignIn() {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState(false);
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const { login, currentUser } = useAuth();
  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setLoading(true);
      await login("dnalifegreen@gmail.com", password);
      //await login("a@a.com", "123456"); // for dev
      history.push("/admin/dashboard");
    } catch {
      alert("Failed to log in");
    }
    setLoading(false);
  }
  return (
    <div className="page">
      <Helmet>
        <title>DNALife | Admin Login</title>
        <meta name="description" content="Admin Login Page" />
        <meta name="author" content="Gaurav V, Divya" />
      </Helmet>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          <form className={classes.form} noValidate onSubmit={handleSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              value="dnalifegreen@gmail.com"
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              disabled
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              autoFocus
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {loading ? (
              <Button fullWidth className={classes.submit}>
                <CircularProgress
                  style={{
                    margin: "0.5rem",
                    width: "30px",
                    height: "30px",
                    color: "white",
                  }}
                />
              </Button>
            ) : (
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Sign In
              </Button>
            )}
          </form>
        </div>
      </Container>
    </div>
  );
}
