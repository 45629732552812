import React, { useEffect, useState } from "react";

import { db } from "../../../../firebase";

import DeleteForever from "@material-ui/icons/Delete";

import { Paper } from "@material-ui/core";

import LinkIcon from "@material-ui/icons/Link";

import Typography from "@material-ui/core/Typography";

import Empty from "../../../../components/Empty/Empty";
import { Button } from "@material-ui/core";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Edit() {
  const [videos, setVideos] = useState([]);
  const [open, setOpen] = useState(false);
  const [isSuccess, setSuccess] = useState("no op");

  useEffect(() => {
    db.collection("Videos").onSnapshot((snapshot) => {
      let items = [];
      snapshot.forEach((doc) => {
        let data = {
          id: doc.id,
          link: doc.data().link,
        };
        items.push(data);
      });
      setVideos(items);
    });
  }, []);

  const deleteVideo = (video) => {
    db.collection("Videos")
      .doc(video.id)
      .delete()
      .then((res) => {
        setOpen(true);
        setSuccess("true");
      })
      .catch((err) => {
        console.log(err);
        setOpen(true);
        setSuccess("false");
      });
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <div>
      {videos.length <= 0 ? (
        <div style={{ marginTop: "1rem" }}>
          <Empty message={"No posts have been added yet !"} />
        </div>
      ) : (
        videos.map((video, index) => (
          <Paper key={index} elevation={15} square className="paper">
            <Typography
              variant="h6"
              className="paper-title"
              style={{ display: "flex", alignItems: "center" }}
            >
              <LinkIcon />
              <a
                href={video.link}
                style={{ color: "orange", marginLeft: "1rem" }}
                target="_blank"
              >
                {video.link}
              </a>
            </Typography>
            <div className="actions">
              <Button
                variant="contained"
                color="secondary"
                className="action-btn"
                onClick={() => {
                  deleteVideo(video);
                }}
                startIcon={<DeleteForever />}
              >
                Delete
              </Button>
            </div>
          </Paper>
        ))
      )}
      {isSuccess == "true" ? (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            Video deleted succesfully !
          </Alert>
        </Snackbar>
      ) : isSuccess == "false" ? (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            Couldn't delete video !
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
    </div>
  );
}
