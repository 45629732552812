import React from "react";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import Carousel from "./Carousel";
import Posts from "./Posts";
import Videos from "./Videos";

import All from "./All";

import { Helmet } from "react-helmet";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Tabhome() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="page admin-activities">
      <Helmet>
        <title>DNALife | Gallery</title>
        <meta
          name="description"
          content="Get a glimpse of the work we do in our gallery in the form of photos and videos"
        />
        <meta
          name="keywords"
          content="DNALife, DNA Life, gallery, videos, posts, carousel, Malali Gowda, Gaurav V, Divya"
        />
        <meta name="author" content="Gaurav V, Divya" />
      </Helmet>
      <AppBar style={{ zIndex: 10 }} position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="All Photos" {...a11yProps(0)} />
          <Tab label="Videos" {...a11yProps(1)} />
          <Tab label="Carousel" {...a11yProps(2)} />
          <Tab label="Posts" {...a11yProps(3)} />
        </Tabs>
      </AppBar>
      <TabPanel style={{ width: "100%" }} value={value} index={0}>
        <All />
      </TabPanel>
      <TabPanel style={{ width: "100%" }} value={value} index={1}>
        <Videos />
      </TabPanel>
      <TabPanel style={{ width: "100%" }} value={value} index={2}>
        <Carousel />
      </TabPanel>
      <TabPanel style={{ width: "100%" }} value={value} index={3}>
        <Posts />
      </TabPanel>
    </div>
  );
}
