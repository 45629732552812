import React from "react";
import { Link } from "react-router-dom";

import er from "../../assets/Donate/undraw_dreamer_gxxi.svg";

import "./CompletedDonation.scss";

import { Helmet } from "react-helmet";

export default function CompletedDonation() {
  return (
    <div className="page nfa">
      <Helmet>
        <title>DNALife | Thank You</title>
        <meta
          name="description"
          content="Thank You for your generous donation"
        />
        <meta
          name="keywords"
          content="DNALife, DNA Life, donate, donation, online payment, credit, debit, card, wallet, transaction, neft, rtgs, thank you, plant a tree, Malali Gowda, Gaurav V, Divya"
        />
        <meta name="author" content="Gaurav V, Divya" />
      </Helmet>
      <h1>
        Thank You for your Generous Help! Your Reciept will be mailed to you.
      </h1>
      <img src={er} />
      <Link className="btn" to="/">
        Go Back Home
      </Link>
    </div>
  );
}
