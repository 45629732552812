import React, { useEffect, useState } from "react";
import { storage } from "../../../firebase";

import Heading from "../../../components/Heading/Heading";
import Lazy from "../../../components/LazyImg/Lazy";

import Empty from "../../../components/Empty/Empty";

import "./template.scss";

import { Helmet } from "react-helmet";

export default function Template({ folderName }) {
  const [images, setImage] = useState([]);
  const [isDone, setDone] = useState(false);

  useEffect(() => {
    var storageRef = storage.ref("Gallery/" + folderName);
    // Now we get the references of these images
    storageRef
      .listAll()
      .then(function (result) {
        result.items.forEach(function (imageRef, index, array) {
          // And finally display them
          imageRef
            .getDownloadURL()
            .then(function (url) {
              setImage((images) => [...images, url]);
            })
            .catch(function (error) {
              // Handle any errors
            });
          if (index === array.length - 1) {
            setTimeout(() => {
              setDone(true);
            }, 500);
          }
        });
      })
      .catch(function (error) {
        // Handle any errors
      });
  }, []);

  return (
    <div className="page">
      <Heading pageTitle={folderName} />
      <div className="lazy-img-wrapper">
        {isDone ? (
          images.map((img, index) => (
            <div key={index} className="lazy-img">
              <Lazy image={img} />
            </div>
          ))
        ) : (
          <div style={{ margin: "0 2rem", width: "100%" }}>
            <Empty message={"Fetching data from database ..."} />
          </div>
        )}
      </div>
    </div>
  );
}
