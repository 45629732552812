import React from "react";

import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import Calendar from "./Calendar";
import DoDont from "./DoDont";
import KidsVideos from "./KidsVideos";
import GreenerEarth from "./GreenerEarth";
import KidsArticles from "./KidsArticles";
import QuizWorksheet from "./QuizWorksheet";

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <div>{children}</div>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function Tabhome() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="page admin-activities">
      <AppBar style={{ zIndex: 10 }} position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab label="Make a Greener Earth" {...a11yProps(0)} />
          <Tab label="Educational Articles" {...a11yProps(1)} />
          <Tab label="Environment Calendar" {...a11yProps(2)} />
          <Tab label="Educational Videos" {...a11yProps(3)} />
          <Tab label="Quizzes and Worksheets" {...a11yProps(4)} />
          <Tab label="Do's and Dont's" {...a11yProps(5)} />
        </Tabs>
      </AppBar>
      <TabPanel style={{ width: "100%" }} value={value} index={0}>
        <GreenerEarth />
      </TabPanel>
      <TabPanel style={{ width: "100%" }} value={value} index={1}>
        <KidsArticles />
      </TabPanel>
      <TabPanel style={{ width: "100%" }} value={value} index={2}>
        <Calendar />
      </TabPanel>
      <TabPanel style={{ width: "100%" }} value={value} index={3}>
        <KidsVideos />
      </TabPanel>
      <TabPanel style={{ width: "100%" }} value={value} index={4}>
        <QuizWorksheet />
      </TabPanel>
      <TabPanel style={{ width: "100%" }} value={value} index={5}>
        <DoDont />
      </TabPanel>
    </div>
  );
}
