import React from "react";
import Template from "./Template";

export default function Independence() {
  return (
    <Template
      folderName={
        "Independence Day - Distribution of certificates and plants to the forest farmers and volunteers"
      }
    />
  );
}
