// dev keys
// module.exports = {
//   apiKey: "AIzaSyAtO9IMrNrFVU40pHMxTD17a7sqwsH0nBo",
//   authDomain: "dnalife-test.firebaseapp.com",
//   projectId: "dnalife-test",
//   storageBucket: "dnalife-test.appspot.com",
//   messagingSenderId: "822155372303",
//   appId: "1:822155372303:web:5dd247342997aa2bca007a",
//   measurementId: "G-VDG94VBCW2",
// };

// production keys
module.exports = {
  apiKey: "AIzaSyALS42hx6Hj9OxQjSJ35PS3sx_CJ5ybI_0",
  authDomain: "dnalife-website.firebaseapp.com",
  projectId: "dnalife-website",
  storageBucket: "dnalife-website.appspot.com",
  messagingSenderId: "433588196297",
  appId: "1:433588196297:web:60142aaf818cfaa307ce18",
  measurementId: "G-9J7LDT7ZN2",
};
