import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Heading from "../Heading/Heading.js";
import Post from "./Post5.js";

import { db, storage, storageRef } from "../../firebase";

import "./posts.scss";

import Empty from "../Empty/Empty.js";

export default function Type5({ type }) {
  const [data, setData] = useState([]);
  useEffect(() => {
    db.collection(type).onSnapshot((snapshot) => {
      let items = [];
      snapshot.forEach((doc) => {
        let data = {
          id: doc.id,
          title: doc.data().title,
          type: doc.data().type,
          subtitle: doc.data().subtitle,
          description: doc.data().description,
          filename: doc.data().filename,
          url: doc.data().url,
        };
        items.push(data);
      });
      setData(items);
    });
  }, []);

  return (
    <div className="page type1">
      <div className="all-type1-posts">
        {data.length <= 0 ? (
          <Empty message={"No posts have been added yet !"} />
        ) : (
          data.map((d) => <Post key={d.id} data={d} />)
        )}
      </div>
    </div>
  );
}
