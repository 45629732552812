import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

export default function MyImage({ image }) {
  return (
    <LazyLoadImage
      alt="gallery"
      effect="blur"
      width="100%"
      height="100%"
      style={{ objectFit: "cover" }}
      src={image}
    />
  );
}
