import React from "react";

import Heading from "../../../components/Heading/Heading";
import Posts from "../../../components/Posts/Type5";

import "./KidsCorner.scss";

export default function KidsArticles() {
  return (
    <div className="page">
      <Heading pageTitle={"Kids Corner : Education Articles 📜"} />
      <Posts type={"KidsEducationalArticles"} />
    </div>
  );
}
