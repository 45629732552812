import React, { useState, useEffect } from "react";
import Heading from "../../components/Heading/Heading";
import Empty from "../../components/Empty/Empty";

import { db } from "../../firebase";

import "./GP.scss";

import {Helmet} from "react-helmet";

export default function Videos() {
  const [videos, setVideos] = useState([]);

  useEffect(() => {
    db.collection("Videos").onSnapshot((snapshot) => {
      let items = [];
      snapshot.forEach((doc) => {
        let data = {
          id: doc.id,
          link: doc.data().link,
        };
        items.push(data);
      });
      setVideos(items);
    });
  }, []);

  return (
    <div className="page videos-page">
      <Heading pageTitle={"Videos"} />
      {videos.length <= 0 ? (
        <div style={{ width: "100%", margin: "2rem" }}>
          <Empty message={"No posts have been added yet !"} />
        </div>
      ) : (
        <div className="videos-container">
          {videos.map((video, index) => (
            <div key={index} className="videos">
              <iframe
                src={video.link}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}
