import React from "react";
import Template from "./Template";

export default function Community() {
  return (
    <Template
      folderName={"Felicitation of Farmers and Volunteers at Anuganalu Village"}
    />
  );
}
