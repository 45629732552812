import { Button, Paper } from "@material-ui/core";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import LinearProgress from "@material-ui/core/LinearProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
}));

function truncateString(longString) {
  if (longString.length > 30) {
    return longString.substring(0, 100) + " " + " " + ". . . ";
  } else {
    return longString;
  }
}

function truncateTitle(longString) {
  if (longString.length > 70) {
    return longString.substring(0, 70) + " " + " " + ". . . ";
  } else {
    return longString;
  }
}

export default function Post({ data, type }) {
  const classes = useStyles();

  const [imgLoaded, setImgLoaded] = useState(false);
  const handleImageLoaded = () => {
    setImgLoaded(true);
  };

  const handleImageErrored = () => {
    setImgLoaded(false);
    console.log("Couldn't load image, Please refresh");
  };

  return (
    <Paper elevation={15} square className="post-card">
      <div className="title">
        <div className="details">
          <h1>{truncateTitle(data.title)}</h1>
          {data.eventDate && <h4>{data.eventDate}</h4>}
        </div>
      </div>
      <div className="body">
        <div className="image-wrapper">
          <img
            onLoad={handleImageLoaded}
            onError={handleImageErrored}
            src={data.url}
            alt="post-img"
            style={{ objectFit: "contain" }}
          />
        </div>
        {imgLoaded ? (
          ""
        ) : (
          <div className={classes.root}>
            <LinearProgress />
          </div>
        )}
        {data.description && (
          <p className="data-description">
            <span
              dangerouslySetInnerHTML={{
                __html: truncateString(data.description),
              }}
            />
          </p>
        )}
      </div>
      {/* <div className="btn-spacer"></div> */}

      <div className="actions">
        {data.link && (
          <a href={data.link} className="register-btn" target="_blank">
            <Button
              fullWidth
              variant="contained"
              style={{ marginBottom: "0.75rem" }}
              color="green"
            >
              Register
            </Button>
          </a>
        )}
        {data.title && (
          <Link to={"/" + type + "/post/" + data._id}>
            <Button
              fullWidth
              variant="contained"
              className="read-more-link"
              color="primary"
            >
              Read More
            </Button>
          </Link>
        )}
      </div>
    </Paper>
  );
}
