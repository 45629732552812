import React from "react";
import Template from "./Template";

export default function Hoff() {
  return (
    <Template
      folderName={
        "Principal Chief Conservator of Forests Head of Forest Force Visit to Anuganalu, Hassan"
      }
    />
  );
}
