import React from "react";

import Heading from "../../../components/Heading/Heading";

import "./KidsCorner.scss";

export default function Calendar() {
  return (
    <div className="page">
      <Heading pageTitle={"Kids Corner : Environmental Calendar 🗓"} />
      <div style={{ marginTop: "3rem" }}></div>
      <div className="comp">
        <p>Here is a list of Important Days for Environment.</p>
        <table className="calendar">
          <tr className="table-row">
            <td className="day">
              <h5>World Wetland Day</h5>
            </td>
            <td>
              <h5>2 February</h5>
            </td>
            <td>
              <h5>
                <a target="_blank" href="https://www.worldwetlandsday.org/">
                  Read More
                </a>
              </h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>National Science Day</h5>
            </td>
            <td>
              <h5>28 February</h5>
            </td>
            <td>
              <h5>
                <a
                  target="_blank"
                  href="http://cpcbenvis.nic.in/kids_corner_main.html#"
                >
                  Read More
                </a>
              </h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>World Forestry Day</h5>
            </td>
            <td>
              <h5>21 March</h5>
            </td>
            <td>
              <h5>
                <a
                  target="_blank"
                  href="https://www.un.org/en/observances/forests-and-trees-day"
                >
                  Read More
                </a>
              </h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>World Water Day</h5>
            </td>
            <td>
              <h5>22 March</h5>
            </td>
            <td>
              <h5>
                <a target="_blank" href="https://www.worldwaterday.org/">
                  Read More
                </a>
              </h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>World Meteorological Day</h5>
            </td>
            <td>
              <h5>23 March</h5>
            </td>
            <td>
              <h5>
                <a
                  target="_blank"
                  href="https://public.wmo.int/en/resources/world-meteorological-day"
                >
                  Read More
                </a>
              </h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>World Health Day</h5>
            </td>
            <td>
              <h5> 7 April</h5>
            </td>
            <td>
              <h5>
                <a
                  target="_blank"
                  href="https://www.who.int/westernpacific/news/events/world-health-day"
                >
                  Read More
                </a>
              </h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>World Heritage Day</h5>
            </td>
            <td>
              <h5>18 April</h5>
            </td>
            <td>
              <h5>
                <a
                  target="_blank"
                  href="https://en.wikipedia.org/wiki/International_Day_For_Monuments_and_Sites"
                >
                  Read More
                </a>
              </h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>Earth Day</h5>
            </td>
            <td>
              <h5>22 April</h5>
            </td>
            <td>
              <h5>
                <a target="_blank" href="https://www.earthday.org/">
                  Read More
                </a>
              </h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>Anti Tobacco Day</h5>
            </td>
            <td>
              <h5>31 May</h5>
            </td>
            <td>
              <h5>
                <a
                  target="_blank"
                  href="https://www.who.int/campaigns/world-no-tobacco-day"
                >
                  Read More
                </a>
              </h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>World Environment Day</h5>
            </td>
            <td>
              <h5>5 June</h5>
            </td>
            <td>
              <h5>
                <a
                  target="_blank"
                  href="https://www.worldenvironmentday.global/"
                >
                  Read More
                </a>
              </h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>World Population Day</h5>
            </td>
            <td>
              <h5>11 July</h5>
            </td>
            <td>
              <h5>
                <a
                  target="_blank"
                  href="https://www.un.org/en/observances/world-population-day"
                >
                  Read More
                </a>
              </h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>World Ozone Day</h5>
            </td>
            <td>
              <h5>16 September</h5>
            </td>
            <td>
              <h5>
                <a
                  target="_blank"
                  href="https://www.un.org/en/observances/ozone-day"
                >
                  Read More
                </a>
              </h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>Green Consumer Day</h5>
            </td>
            <td>
              <h5>28 September</h5>
            </td>
            <td>
              <h5>
                <a
                  target="_blank"
                  href="https://en.wikipedia.org/wiki/Green_consumption"
                >
                  Read More
                </a>
              </h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>World Habitat Day</h5>
            </td>
            <td>
              <h5>3 October</h5>
            </td>
            <td>
              <h5>
                <a
                  target="_blank"
                  href="https://www.un.org/en/observances/habitat-day"
                >
                  Read More
                </a>
              </h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>World Animal Welfare Day</h5>
            </td>
            <td>
              <h5>4 October</h5>
            </td>
            <td>
              <h5>
                <a
                  target="_blank"
                  href="https://www.worldanimalday.org.uk/about_us"
                >
                  Read More
                </a>
              </h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5> International Day for Natural Disaster Reduction</h5>
            </td>
            <td>
              <h5>13 October</h5>
            </td>
            <td>
              <h5>
                <a
                  target="_blank"
                  href="https://www.un.org/en/observances/disaster-reduction-day"
                >
                  Read More
                </a>
              </h5>
            </td>
          </tr>
          <tr>
            <td className="day">
              <h5>Children’s Day in India</h5>
            </td>
            <td>
              <h5>14 November</h5>
            </td>
            <td>
              <h5>
                <a
                  target="_blank"
                  href="https://en.wikipedia.org/wiki/Children%27s_Day_(India)"
                >
                  Read More
                </a>
              </h5>
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
}
