import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Heading from "../Heading/Heading.js";
import Post from "./Post.js";

import { getData } from "../../actions";

import "./posts.scss";

import { Helmet } from "react-helmet";

import Empty from "../Empty/Empty.js";

export default function Type1({ title, type }) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getData(type));
  }, []);
  const [ltitle, setLtitle] = useState("Fetching Data ...");
  const reducerData = useSelector((state) =>
    type === "Internships"
      ? state.internshipReducer.education
      : type === "Trainings"
      ? state.trainingReducer.education
      : type === "CertificateCourses"
      ? state.certificateCourseReducer.education
      : type === "EducationalTours"
      ? state.educationTourReducer.activities
      : type === "EcoProjects"
      ? state.ecoProjectReducer.activities
      : type === "PlantationDrives"
      ? state.plantationDriveReducer.activities
      : type === "CleaningMovements"
      ? state.cleaningMovementReducer.activities
      : null
  );
  const pageDescription =
    type === "Internships"
      ? "DNA Life internships are open for people who are motivated to protect nature. Join us to acquire scientific knowledge from global experts in the fields like Greenomics, Genomics, Forest Farming and Environmental Engineering. Selected candidates may gain an opportunity to work in DNA Life projects. Get discovered."
      : type === "Trainings"
      ? "DNA Life Organisation offers training on environmental awareness, agricultural practices, social forestry, mixed farming and rural livelihood. We invite schools, colleges, NGOs, Housing Societies etc. to join us to learn eco-friendly practices and contribute to the conservation of nature."
      : type === "CertificateCourses"
      ? "Join our short term online/onsite interdisciplinary courses on mixed farming, rainforests, biodiversity and gardening etc. featuring live demonstration and interaction with farmers, environmentalists, conservationists and eco-educators."
      : type === "EducationalTours"
      ? "Join the adventure with DNA Life to understand different tools and techniques of farming, indwelling of local heritage with nature, ancient medicinal system (Nati- Vaidya), visiting rain hill and much more. Practical learning plus the fun!"
      : type === "EcoProjects"
      ? "Together, we can heal our Planet and return its glory that is lost due to over exploitation and sheer negligence of ages. We are strong when we are with nature! DNA Life Organisation is striving towards climate change mitigation through various projects and initiatives based on Good Agricultural Practices (GAP) to improve the safety and quality of the produce while protecting the environment."
      : type === "PlantationDrives"
      ? "Plantation Drive To Increase India's Green Cover. Tree Plantation drives combat many environmental issues like deforestation, erosion of soil, desertification in semi-arid areas, global warming and hence enhancing the beauty and balance of the environment"
      : type === "CleaningMovements"
      ? "Today, our ecosystems are overflowing with waste materials like plastics, sewage and industrial effluents. It is immensely painful to see, what we have made of Mother Nature! From sky to oceans and the ground, anthropogenic activities are damaging our Planet. "
      : null;

  const pageKeyWords =
    type === "Internships" 
      ? "intern, internship"
      : type === "Trainings"
      ? "trainings, Ecological Awareness Workshops for School Children, Ecological and Agricultural Workshops for Farmers"
      : type === "CertificateCourses"
      ? "certificate, courses, Mixed Planting - Sky Green Approaches, Online Certification Course on Mixed Farming, Online Certification Course on Gardening"
      : type === "EducationalTours"
      ? "education, tours, educational"
      : type === "EcoProjects"
      ? "DNA Seeds Bags, Rain Water Harvesting and Recharging Ground Water, Establishment of a Nursery to Grow Local and Forest Plant Species, Conservation and Documentation of Medicinal Plants, Afforestation of Barren Rocky Land, Restoration of Rain Hills - Seegegudda or Malegudda and Nanjedevaragudda"
      : type === "PlantationDrives"
      ? "plant, plantation, drives, plantation drives"
      : type === "CleaningMovements"
      ? "cleaning, cleaning movements, movements"
      : null;

  return (
    <div className="page type1">
      <Helmet>
        <title>DNALife | {title}</title>
        <meta name="description" content={pageDescription} />
        <meta
          name="keywords"
          content={
            "DNALife, DNA Life," +
            pageKeyWords +
            ",Malali Gowda, Gaurav V, Divya"
          }
        />
        <meta name="author" content="Gaurav V, Divya" />
      </Helmet>
      <Heading pageTitle={title} />
      <div className="all-type1-posts">
        {reducerData.length <= 0 ? (
          <Empty message={"No posts have been added yet !"} />
        ) : (
          reducerData.map((rd) => <Post key={rd._id} data={rd} type={type} />)
        )}
      </div>
    </div>
  );
}
