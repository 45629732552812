import React, { useEffect } from "react";
import "./Announcements.scss";
import Heading from "../../components/Heading/Heading";

import AllDocs from "../../components/Posts/Type4";

import { useSelector, useDispatch } from "react-redux";
import { getData } from "../../actions";

import { Helmet } from "react-helmet";

export default function Docs() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getData("Resources"));
  }, []);
  const reducerData = useSelector((state) => state.resourceReducer.resources);
  return (
    <div className="page announcements-page">
      <Helmet>
        <title>DNALife | Resources & Publications</title>
        <meta
          name="description"
          content="Latest news & updates related to DNA Life Organisation"
        />
        <meta
          name="keywords"
          content="DNALife, DNA Life, news, updates, documents, resources, publications, Malali Gowda, Gaurav V, Divya"
        />
        <meta name="author" content="Gaurav V, Divya" />
      </Helmet>
      <Heading pageTitle={"Resources and Publications"} />
      <AllDocs data={reducerData} />
    </div>
  );
}
