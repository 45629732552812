import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./App.scss";

// redux
import { Provider } from "react-redux";
import store from "./store";

import ScrollToTop from "./ScrollToTop";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";

// pages
import Home from "./pages/Home/Home.js";
import ContactUs from "./pages/ContactUs/ContactUs.js";
import AboutUs from "./pages/AboutUs/AboutUs.js";
import Team from "./pages/Team/Team.js";
import JoinUs from "./pages/JoinUs/JoinUs.js";
import Donate from "./pages/Donate/Donate.js";
import Village from "./pages/Anuganalu/Anuganalu";

// gallery
import Gallery from "./pages/Gallery/Tabhome";
import GCleaning from "./pages/Gallery/All/Cleaning";
import GCommunity from "./pages/Gallery/All/Community";
import GFelicitation from "./pages/Gallery/All/Felicitation";
import GGreen from "./pages/Gallery/All/Green";
import GPlanting from "./pages/Gallery/All/Planting";
import GIndependence from "./pages/Gallery/All/Independence";
import GUbiquitous from "./pages/Gallery/All/Ubiquitous";
import GHoff from "./pages/Gallery/All/Hoff";
import GAnuganalu from "./pages/Gallery/All/Anuganalu";
import GForest from "./pages/Gallery/All/Forest"; // Import the Forest component
import GScienceday from "./pages/Gallery/All/Scienceday";

// donation
import AccountDetails from "./pages/Donate/AccountDetails.js";
import CompletedDonation from "./pages/Donate/CompletedDonation";

// activities
import Cleaning from "./pages/Activities/Cleaning Movements/Cleaning.js";
import CleaningPosts from "./pages/Activities/Cleaning Movements/Posts.js";
import Education from "./pages/Activities/Educational Tours/Educational.js";
import EducationPosts from "./pages/Activities/Educational Tours/Posts.js";
import Eco from "./pages/Activities/Eco Projects/Eco.js";
import EcoPosts from "./pages/Activities/Eco Projects/Posts.js";
import Plantation from "./pages/Activities/Plantation Drives/Plantation.js";
import PlantationPosts from "./pages/Activities/Plantation Drives/Posts.js";

// announcements
import Announcements from "./pages/Announcements/Announcements.js";
import Resources from "./pages/Announcements/Resources.js";
import Docs from "./pages/Announcements/Docs.js";
import News from "./pages/Announcements/News.js";

// education
import Internship from "./pages/Education/Internship/Internship.js";
import InternshipPosts from "./pages/Education/Internship/Posts.js";
import MS from "./pages/Education/MS/Ms.js";
import Training from "./pages/Education/Training/Training.js";
import TrainingPosts from "./pages/Education/Training/Posts.js";
import Courses from "./pages/Education/Courses/Courses.js";
import CoursesPosts from "./pages/Education/Courses/Posts.js";

// kids corner
import KidsCorner from "./pages/Education/KidsCorner/Tabhome";
import Calendar from "./pages/Education/KidsCorner/Calendar";
import DoDont from "./pages/Education/KidsCorner/DoDont";
import GreenerEarth from "./pages/Education/KidsCorner/GreenerEarth";
import KidsArticles from "./pages/Education/KidsCorner/KidsArticles";
import QuizWorksheet from "./pages/Education/KidsCorner/QuizWorksheet";

// admin
import SignIn from "./pages/Admin/SignIn/Signin.js";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./PrivateRoutes";
import Dashboard from "./pages/Admin/Dashboard/Dashboard.js";
import AdminAnnouncements from "./pages/Admin/Announcements/NewHome.js";
import AdminActivities from "./pages/Admin/Activities/Activities.js";
import AdminEducation from "./pages/Admin/Education/Education.js";
import AdminPhotos from "./pages/Admin/Photos/TabHome";
import AdminKidsCorner from "./pages/Admin/KidsCorner/KidsCorner";

// testing
import PostDetailed from "./components/Posts/PostDetailed.js";
import KidsDetailed from "./components/Posts/KidsDetailed.js";

// 404
import E404 from "./pages/404/404.js";

// T & C
import Terms from "./pages/Terms/Terms.js";
import Privacy from "./pages/Terms/Privacy";
import Refund from "./pages/Terms/Refund";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <AuthProvider>
          <div className="App">
            <Header />
            <div className="routes">
              <ScrollToTop>
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/contact-us" component={ContactUs} />
                  <Route exact path="/about-us" component={AboutUs} />
                  <Route exact path="/team" component={Team} />
                  <Route exact path="/join-us" component={JoinUs} />
                  <Route
                    exact
                    path="/education/ms-program/nelson-institute-for-environmental-studies"
                    component={MS}
                  />
                  <Route
                    exact
                    path="/education/trainings"
                    component={Training}
                  />
                  <Route
                    exact
                    path="/education/trainings/posts"
                    component={TrainingPosts}
                    />
                    <Route
                    exact
                    path="/education/internships"
                    component={Internship}
                  />
                  <Route
                    exact
                    path="/education/internships/posts"
                    component={InternshipPosts}
                  />
                  <Route
                    exact
                    path="/education/certificate-courses"
                    component={Courses}
                  />
                  <Route
                    exact
                    path="/education/certificate-courses/posts"
                    component={CoursesPosts}
                  />
                  <Route
                    exact
                    path="/activities/educational-tours"
                    component={Education}
                  />
                  <Route
                    exact
                    path="/activities/educational-tours/posts"
                    component={EducationPosts}
                  />
                  <Route
                    exact
                    path="/activities/eco-projects"
                    component={Eco}
                  />
                  <Route
                    exact
                    path="/activities/eco-projects/posts"
                    component={EcoPosts}
                  />
                  <Route
                    exact
                    path="/activities/plantation-drives"
                    component={Plantation}
                  />
                  <Route
                    exact
                    path="/activities/plantation-drives/posts"
                    component={PlantationPosts}
                  />
                  <Route
                    exact
                    path="/activities/cleaning-movements"
                    component={Cleaning}
                  />
                  <Route
                    exact
                    path="/activities/cleaning-movements/posts"
                    component={CleaningPosts}
                  />
                  <Route exact path="/anuganalu-village" component={Village} />
                  <Route
                    exact
                    path="/announcements"
                    component={Announcements}
                  />
                  <Route
                    exact
                    path="/announcements/resources"
                    component={Resources}
                  />
                  <Route exact path="/announcements/news" component={News} />
                  <Route exact path="/announcements/docs" component={Docs} />

                  <Route exact path="/gallery" component={Gallery} />
                  <Route
                    exact
                    path="/gallery/all/cleaning-drives"
                    component={GCleaning}
                  />
                  <Route
                    exact
                    path="/gallery/all/community-interaction"
                    component={GCommunity}
                  />
                  <Route
                    exact
                    path="/gallery/all/felicitation"
                    component={GFelicitation}
                  />
                  <Route
                    exact
                    path="/gallery/all/green-education"
                    component={GGreen}
                  />
                  <Route
                    exact
                    path="/gallery/all/planting-drives"
                    component={GPlanting}
                  />
                  <Route
                    exact
                    path="/gallery/all/independence-day"
                    component={GIndependence}
                  />
                  <Route
                    exact
                    path="/gallery/all/planting-is-ubiquitous"
                    component={GUbiquitous}
                  />
                  <Route
                    exact
                    path="/gallery/all/visit-by-hoff"
                    component={GHoff}
                  />
                  <Route
                    exact
                    path="/gallery/all/visit-to-anuganalu"
                    component={GAnuganalu}
                  />
                  <Route
                    exact
                    path="/gallery/all/forests"
                    component={GForest}
                  />
                  <Route
                    exact
                    path="/gallery/all/science"
                    component={GScienceday}
                  />

                  <Route exact path="/signIn" component={SignIn} />
                  <Route exact path="/donate" component={Donate} />
                  <Route
                    exact
                    path="/donate/account-details"
                    component={AccountDetails}
                  />
                  <Route
                    exact
                    path="/donate/donation-complete"
                    component={CompletedDonation}
                  />
                  <Route
                    exact
                    path="/:type/post/:id"
                    component={PostDetailed}
                  />
                  <Route
                    exact
                    path="/kids-corner/:type/:id"
                    component={KidsDetailed}
                  />
                  <Route exact path="/termsandconditions" component={Terms} />
                  <Route exact path="/privacypolicy" component={Privacy} />
                  <Route exact path="/refund" component={Refund} />
                  <Route exact path="/kids-corner" component={KidsCorner} />
                  <Route
                    exact
                    path="/kids-corner/environmental-calendar"
                    component={Calendar}
                  />
                  <Route
                    exact
                    path="/kids-corner/dos-and-donts"
                    component={DoDont}
                  />
                  <Route
                    exact
                    path="/kids-corner/make-a-greener-earth"
                    component={GreenerEarth}
                  />
                  <Route
                    exact
                    path="/kids-corner/educational-articles"
                    component={KidsArticles}
                  />
                  <Route
                    exact
                    path="/kids-corner/quizzes-and-worksheets"
                    component={QuizWorksheet}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/dashboard"
                    component={Dashboard}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/announcements"
                    component={AdminAnnouncements}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/activities"
                    component={AdminActivities}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/education"
                    component={AdminEducation}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/photos"
                    component={AdminPhotos}
                  />
                  <PrivateRoute
                    exact
                    path="/admin/kids-corner"
                    component={AdminKidsCorner}
                  />
                  <Route path="*" component={E404} />
                </Switch>
              </ScrollToTop>
            </div>
            <Footer />
          </div>
        </AuthProvider>
      </Router>
    </Provider>
  );
}

export default App;