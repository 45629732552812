import React, { useEffect, useState } from "react";

import { db, storage, storageRef } from "../../../../firebase";

import DeleteForever from "@material-ui/icons/Delete";

import TextField from "@material-ui/core/TextField";
import { Paper } from "@material-ui/core";

import ClearIcon from "@material-ui/icons/Clear";
import LinkIcon from "@material-ui/icons/Link";
import SaveIcon from "@material-ui/icons/Save";
import EditIcon from "@material-ui/icons/Edit";
import ViewIcon from "@material-ui/icons/Visibility";
import TodayIcon from "@material-ui/icons/Today";
import ImageIcon from "@material-ui/icons/Image";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import UploadIcon from "@material-ui/icons/CloudUpload";
import TitleIcon from "@material-ui/icons/Title";
import DescriptionIcon from "@material-ui/icons/Description";

import Typography from "@material-ui/core/Typography";

import Empty from "../../../../components/Empty/Empty";
import { Button } from "@material-ui/core";

import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

import CircularProgress from "@material-ui/core/CircularProgress";

import { makeStyles } from "@material-ui/core/styles";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Edit() {
  const classes = useStyles();

  const [articles, setArticles] = useState([]);

  const [open, setOpen] = useState(false);
  const [isSuccess, setSuccess] = useState("no op");

  const [modal, setModal] = useState(false);
  const [view, setView] = useState(false);

  const [instance, setInstance] = useState([]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFilePicked, setIsFilePicked] = useState(false);

  const [fullWidth, setFullWidth] = useState(true);
  const [maxWidth, setMaxWidth] = useState("md");
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(false);
  const [del, setDel] = useState(false);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    db.collection("KidsEducationalArticles").onSnapshot((snapshot) => {
      let items = [];
      snapshot.forEach((doc) => {
        let data = {
          id: doc.id,
          title: doc.data().title,
          type: doc.data().type,
          subtitle: doc.data().subtitle,
          description: doc.data().description,
          filename: doc.data().filename,
          url: doc.data().url,
        };
        items.push(data);
      });
      setArticles(items);
    });
  }, []);

  const handleModalOpen = (instance) => {
    setInstance(instance);
    setTitle(instance.title);
    setDescription(instance.description);
    setSubtitle(instance.subtitle);
    setModal(true);
  };

  const handleModalClose = () => {
    setModal(false);
    setInstance([]);
    setTitle("");
    setDescription("");
    setSelectedFile(null);
  };

  const handleViewOpen = (instance) => {
    setInstance(instance);
    setView(true);
  };

  const handleViewClose = () => {
    setView(false);
    setInstance([]);
  };

  const deleteArticle = (article) => {
    const delOldImg = storageRef.child(article.type + "/" + article.filename);
    delOldImg
      .delete()
      .then(() => {
        db.collection("KidsEducationalArticles")
          .doc(article.id)
          .delete()
          .then((res) => {
            setOpen(true);
            setSuccess("true");
          })
          .catch((err) => {
            console.log(err);
            setOpen(true);
            setSuccess("false");
          });
      })
      .catch((err) => {
        console.log(err);
        setOpen(true);
        setSuccess("false");
      });
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleSubmission = () => {
    console.log("in handle");
    console.log("submitted", selectedFile);
  };

  const handlePreview = (img) => {
    const imgURL = URL.createObjectURL(img);
    setPreview(imgURL);
    console.log(imgURL);
  };

  const changeHandler = (event) => {
    console.log("in change");
    setSelectedFile(event.target.files[0]);
    handlePreview(event.target.files[0]);
    setIsFilePicked(true);
  };

  // for updating
  const onSubmit = (e) => {
    e.preventDefault();
    let fileDate = Date.now();
    setLoading(true);
    const data = {
      type: instance.type,
      title,
      description,
      subtitle,
    };
    if (selectedFile) {
      const delOldImg = storageRef.child(data.type + "/" + instance.filename);
      // Delete the file
      delOldImg
        .delete()
        .then(() => {
          // File deleted successfully
          let path = "/" + data.type + "/" + selectedFile.name + fileDate;
          const uploadTask = storage.ref(path).put(selectedFile);
          uploadTask.on(
            "state_changed",
            (snapShot) => {
              //takes a snap shot of the process as it is happening
            },
            (err) => {
              //catches the errors
              console.log(err);
              setLoading(false);
            },
            () => {
              // gets the functions from storage refences the image storage in firebase by the children
              // gets the download url then sets the image from firebase as the value for the imgUrl key:
              storage
                .ref("/" + data.type)
                .child(selectedFile.name + fileDate)
                .getDownloadURL()
                .then((fireBaseUrl) => {
                  data.filename = selectedFile.name + fileDate;
                  data.url = fireBaseUrl;
                  console.log(instance.id, data);
                  db.collection("KidsEducationalArticles")
                    .doc(instance.id)
                    .update(data)
                    .then(() => {
                      setAction("Update");
                      setOpen(true);
                      handleModalClose();
                      setLoading(false);
                      setSuccess("true");
                    })
                    .catch((err) => {
                      console.log(err);
                      setOpen(false);
                      handleModalClose();
                      setLoading(false);
                      setSuccess("false");
                    });
                });
            }
          );
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.log(error);
          setLoading(false);
        });
    } else {
      data.filename = instance.filename;
      data.url = instance.url;
      db.collection("KidsEducationalArticles")
        .doc(instance.id)
        .update(data)
        .then(() => {
          setAction("Update");
          setOpen(true);
          handleModalClose();
          setLoading(false);
          setSuccess("true");
        })
        .catch((err) => {
          console.log(err);
          setOpen(false);
          handleModalClose();
          setLoading(false);
          setSuccess("false");
        });
    }
  };

  return (
    <div>
      {articles.length <= 0 ? (
        <div style={{ marginTop: "1rem" }}>
          <Empty message={"No posts have been added yet !"} />
        </div>
      ) : (
        articles.map((article, index) => (
          <Paper key={index} elevation={15} square className="paper">
            <Typography
              variant="h6"
              className="paper-title"
              style={{ display: "flex", alignItems: "center" }}
            >
              <a
                href={article.url}
                style={{ color: "orange", marginLeft: "1rem" }}
                target="_blank"
              >
                {article.title}
              </a>
            </Typography>
            <div className="actions">
              <Button
                type="submit"
                variant="contained"
                className="action-btn mr"
                startIcon={<ViewIcon />}
                onClick={() => {
                  handleViewOpen(article);
                }}
              >
                View
              </Button>
              <Button
                type="submit"
                className="action-btn mr"
                style={{
                  backgroundColor: "#009688",
                  color: "white",
                }}
                variant="contained"
                startIcon={<EditIcon />}
                onClick={() => {
                  handleModalOpen(article);
                }}
              >
                Edit
              </Button>
              <Button
                variant="contained"
                color="secondary"
                className="action-btn"
                onClick={() => {
                  deleteArticle(article);
                }}
                startIcon={<DeleteForever />}
              >
                Delete
              </Button>
            </div>
          </Paper>
        ))
      )}

      <Dialog
        open={view}
        TransitionComponent={Transition}
        keepMounted
        className="dialog"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleViewClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          className="dialog-title"
          color="primary"
          id="alert-dialog-slide-title"
        >
          View Activity
        </DialogTitle>
        <DialogContent className="dialog-content">
          <form className="post-content">
            <div className="tf-wrapper">
              <TitleIcon />
              <p className="label">{instance.title}</p>
            </div>
            <div className="tf-wrapper">
              <DescriptionIcon />
              <p className="label">{instance.subtitle}</p>
            </div>
            <div className="tf-wrapper">
              <DescriptionIcon />
              <p className="label">
                <div
                  dangerouslySetInnerHTML={{
                    __html: instance.description,
                  }}
                />
              </p>
            </div>
            <div className="tf-wrapper">
              <ImageIcon />
              <p className="label">
                <img
                  style={{ width: "100%", height: "auto" }}
                  src={instance.url}
                />
              </p>
            </div>
            <DialogActions
              style={{ background: "#0e1217", padding: "0.5rem 0 2rem" }}
            >
              <Button
                onClick={handleViewClose}
                variant="contained"
                startIcon={<ClearIcon />}
                color="secondary"
              >
                Close
              </Button>
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>

      <Dialog
        open={modal}
        TransitionComponent={Transition}
        keepMounted
        className="dialog"
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        onClose={handleModalClose}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle
          className="dialog-title"
          color="primary"
          id="alert-dialog-slide-title"
        >
          Edit Activity
        </DialogTitle>
        <DialogContent className="dialog-content ">
          <form onSubmit={onSubmit} className="post-content">
            <div className="tf-wrapper">
              <TitleIcon />
              <TextField
                id={"tf1" + instance._id}
                value={title}
                label="Title"
                variant="filled"
                onChange={(e) => setTitle(e.target.value)}
                className="input"
              />
            </div>
            <div className="tf-wrapper">
              <DescriptionIcon />
              <TextField
                id={"tf10" + instance._id}
                value={subtitle}
                label="Subtitle"
                variant="filled"
                onChange={(e) => setSubtitle(e.target.value)}
                className="input"
              />
            </div>
            <div className="tf-wrapper">
              <DescriptionIcon />
              <TextField
                id={"tf2" + instance._id}
                className="input"
                label="Description ( HTML Editable )"
                variant="filled"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
              />
            </div>
            {selectedFile ? (
              <div className="tf-wrapper">
                <ImageIcon />
                <TextField
                  id="tf3"
                  disabled
                  className="input"
                  label="Photo Filename"
                  variant="filled"
                  value={selectedFile.name}
                />
              </div>
            ) : (
              ""
            )}
            {!loading ? (
              <DialogActions
                className="edit-dialog-content-actions"
                style={{
                  background: "#0e1217",
                }}
              >
                <div className="tf-wrapper1">
                  <input
                    accept="image/*"
                    style={{ display: "none" }}
                    id="contained-button-file"
                    multiple
                    type="file"
                    onChange={changeHandler}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      onClick={handleSubmission}
                      startIcon={<UploadIcon />}
                      variant="contained"
                      component="span"
                      className="upload-button ml"
                    >
                      Update Image
                    </Button>
                  </label>
                </div>
                <div className="main-actions mla">
                  <Button
                    onClick={handleModalClose}
                    variant="contained"
                    startIcon={<ClearIcon />}
                    color="secondary"
                    className="cancel-button"
                  >
                    Cancel
                  </Button>
                  <Button
                    type="submit"
                    className="ml"
                    style={{
                      backgroundColor: "#009688",
                      color: "white",
                    }}
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    Save
                  </Button>
                </div>
              </DialogActions>
            ) : (
              <div className="loading">
                <CircularProgress
                  style={{
                    width: "30px",
                    height: "30px",
                    marginLeft: "0.5rem",
                  }}
                />{" "}
                <p style={{ paddingLeft: "1.5rem" }}>Saving Changes ...</p>
              </div>
            )}
          </form>
        </DialogContent>
      </Dialog>

      {isSuccess == "true" ? (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="success">
            article deleted succesfully !
          </Alert>
        </Snackbar>
      ) : isSuccess == "false" ? (
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity="error">
            Couldn't delete article!
          </Alert>
        </Snackbar>
      ) : (
        ""
      )}
    </div>
  );
}
