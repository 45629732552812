import React from "react";
import "./Announcements.scss";
import { Helmet } from "react-helmet";

import graphic from "../../assets/Announcements/up.png";
import update1 from "../../assets/Announcements/update-4.jpg";
import update2 from "../../assets/Announcements/update-2.jpg";
import update3 from "../../assets/Announcements/update-3.jpg";
import update4 from "../../assets/Announcements/update4.jpg";

export default function Eco() {
  const pageContentStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
  };

  const leftStyle = {
    flex: 1,
    minWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '50px 20px',
  };

  const rightStyle = {
    flex: 1,
    minWidth: '300px',
    padding: '0 20px',
  };

  const imgStyle = {
    padding: '20px',
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '500px',
    objectFit: 'contain',
  };

  const headingStyle = {
    fontSize: '2rem',
    marginBottom: '1rem',
    padding:'20px',
    paddingleft:'20px',
    color:'yellow',
  };

  const paragraphStyle = {
    fontSize: '1.05rem',
    padding:'30px',
    textAlign: 'justify',
    color: 'white',
  };

  return (
    <div className="page internship-page">
      <Helmet>
        <title>DNALife | Updates</title>
        <meta name="description" />
        <meta
          name="keywords"
          content="DNALife, DNA Life, DNA Seeds Bags, Rain Water Harvesting and Recharging Ground Water, Establishment of a Nursery to Grow Local and Forest Plant Species, Conservation and Documentation of Medicinal Plants, Afforestation of Barren Rocky Land, Restoration of Rain Hills - Seegegudda or Malegudda and Nanjedevaragudda, Malali Gowda, Gaurav V, Divya"
        />
        <meta name="author" content="Gaurav V, Divya" />
      </Helmet>
    
      <div style={pageContentStyle}>
        <div style={leftStyle}>
          <img src={graphic} alt="cleaning" style={imgStyle} />
          <img src={update1} alt="cleaning" style={imgStyle} />
          <img src={update2} alt="cleaning" style={imgStyle} />
          <img src={update3} alt="cleaning" style={imgStyle} />
          <img src={update4} alt="cleaning" style={imgStyle} />
        </div>
        <div style={rightStyle}>
          <h1 style={headingStyle}>Exciting updates!</h1>
          <p style={paragraphStyle}>
            Dr. Malali Gowda, Founder of DNA Life was the Guest of Honour in the World Doctor's Day and World Environmental Day at the Ramaiah Medical College, Bengaluru on July 16, 2024.
            As part of World Environmental Day, Dr. Malali Gowda has shared his green experiences, how DNA Life has transformed rocky and agricultural landscape into rainforest at Hassan. Recently, he has founded "School of Forest Farmers" (Kadu Krushikara Shale) at Hassan district. These schools are nothing but natural farm land where farmers are practicing profitable multidimensional mixed plantation without chemical fertilizers, pesticides, and herbicides. He has established 7 schools in Hassan since the World Environmental Day on June 05, 2023.
            These green schools are acting as a nucleus for the farming micro economy and mini rainforest in the farmer's land.
            As part of World Doctor's Day, Dr. Gowda has shared how DNA sequencing has changed the medical practices around the world. He is the pioneer in India by establishing genome/DNA sequencing and artificial intelligence (AI) facilities in public and private organizations.
          </p>
        </div>
      </div>
    </div>
  );
}
