import React from "react";
import emailjs from "emailjs-com";

import Heading from "../../components/Heading/Heading";

import "./AccountDetails.scss";
import "../../scss/custom.scss";

import { Helmet } from "react-helmet";

function sendEmail(e) {
  e.preventDefault();
  if (
    e.target.email.value.trim() == "" ||
    e.target.phone.value.trim() == "" ||
    e.target.pan.value.trim() == "" ||
    e.target.amount.value.trim() == "" ||
    e.target.transactionType.value.trim() == "" ||
    e.target.transactionID.value.trim() == "" ||
    e.target.address.value.trim() == ""
  ) {
    alert("Email not sent. Please fill all the fields!");
  } else {
    if (e.target.name.value.trim() == "") {
      e.target.name.value = "Anonymous";
    }
    emailjs
      .sendForm(
        "service_ykyi7if",
        "template_6tyvxj2",
        e.target,
        "user_Y0p4a5dRdCuQpcOjhhbaz"
      )
      .then(
        (result) => {
          console.log(result.text);
          alert("Email Sent Successfully.");
        },
        (error) => {
          console.log(error.text);
          alert(
            "Unable to send email. Please try again later or contact us at dnalifegreen@gmail.com"
          );
        }
      );
    e.target.reset();
  }
}

export default function AccountDetails() {
  return (
    <div className="page">
      <Helmet>
        <title>DNALife | Donate</title>
        <meta
          name="description"
          content="You can make a donation by doing a NEFT / RTGC / IMPS / other Transaction through your Bank or any other offline-means and request a reciept through the adjacent section thereafter. We will reach out to you with your donation reciept once the payment is confirmed."
        />
        <meta
          name="keywords"
          content="DNALife, DNA Life, donate, neft, rtgs, bank transfer, sbi, receipt request, account details, Malali Gowda, Gaurav V, Divya"
        />
        <meta name="author" content="Gaurav V, Divya" />
      </Helmet>
      <Heading pageTitle={"Account Details and Request Reciept"} />
      <div className="account-details-page">
        <div className="account">
          <h1>Account Details</h1>
          <p>
            You can make a donation by doing a NEFT / RTGC / IMPS / other
            Transaction through your Bank or any other offline-means and request
            a reciept through the adjacent section thereafter. We will reach out
            to you with your donation reciept once the payment is confirmed.
          </p>
          <table className="account-table">
            <tr className="table-row">
              <td className="detail-name">
                <h5>Account No. :</h5>
              </td>
              <td>
                <h5>35972258354</h5>
              </td>
            </tr>
            <tr>
              <td className="detail-name">
                <h5>Account Name :</h5>
              </td>
              <td>
                <h5>DNA Life Organisation</h5>
              </td>
            </tr>
            <tr>
              <td className="detail-name">
                <h5>IFSC :</h5>
              </td>
              <td>
                <h5>SBIN0041203</h5>
              </td>
            </tr>
            <tr>
              <td className="detail-name">
                <h5>MICR :</h5>
              </td>
              <td>
                <h5>560002581</h5>
              </td>
            </tr>
            <tr>
              <td className="detail-name">
                <h5>Bank :</h5>
              </td>
              <td>
                <h5>State Bank of India,</h5>
                <h5>Ananthapura Gate,</h5>
                <h5>Bangalore, Karnataka,</h5>
                <h5>India</h5>
                <h5>PIN : 560064</h5>
              </td>
            </tr>
          </table>
        </div>
        <form onSubmit={sendEmail} className="reciept-request">
          <h1>Reciept Request</h1>
          <input
            id="username"
            type="text"
            className="input"
            name="name"
            placeholder="Your Name"
          />
          <input
            id="userEmail"
            type="text"
            className="input"
            name="email"
            placeholder="Your Email Address"
          />
          <input
            id="phone"
            type="text"
            className="input"
            name="phone"
            placeholder="Your Phone No."
          />
          <input
            id="pan"
            type="text"
            className="input"
            name="pan"
            placeholder="PAN Number"
          />
          <input
            id="amount"
            type="text"
            className="input"
            name="amount"
            placeholder="Donation Amount"
          />
          <input
            id="transactionType"
            type="text"
            className="input"
            name="transactionType"
            placeholder="Transaction Type (NEFT/RTGC/ICMP/Other)"
          />
          <input
            id="transactionID"
            type="text"
            className="input"
            name="transactionID"
            placeholder="Transaction Reference Number/ID"
          />
          <textarea
            id="address"
            className="textarea-message"
            name="address"
            placeholder="Your Address"
          />
          <textarea
            id="userMessage"
            className="textarea-message"
            name="message"
            placeholder="Your Message"
          />

          <button type="submit" className="btn-orange">
            Send
          </button>
        </form>
      </div>
    </div>
  );
}
