import Lazy from "../LazyImg/Lazy";

import React from "react";

import "./Gallery.scss";

var Carousel = require("3d-react-carousal").Carousel;

export default function Gallery() {
  let images = [
    <div style={{ background: "grey" }}>
      <Lazy
        image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg1.webp?alt=media&token=0678d2c5-5ddf-4c14-86a1-91b3bcf1d454"
        alt="carousel img1"
      />
    </div>,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg2.webp?alt=media&token=ca6ee71a-ab4e-49cb-8bef-41750f2a6840"
      alt="carousel img2"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg3.webp?alt=media&token=b53973a1-db40-41cb-bf91-4f6a4a41372b"
      alt="carousel img3"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg3-5.jpeg?alt=media&token=ce46debe-5d8c-4739-a0cd-55dae1398856"
      alt="carousel img3"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg4.webp?alt=media&token=fdb9ff77-e913-4da2-a497-ed618234dcb0"
      alt="carousel img4"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg5.webp?alt=media&token=12eda023-1bb6-4842-a36d-2df0e475b41a"
      alt="carousel img5"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg6.webp?alt=media&token=2ff55d2a-0a1f-4526-ae92-db3f0e1bef3d"
      alt="carousel img6"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg6-5.jpeg?alt=media&token=67f91d20-033b-4ea8-8a4a-913825f12b08"
      alt="carousel img65"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg7.webp?alt=media&token=80f5951e-ad2e-41f2-bf42-75f8592ddbe1"
      alt="carousel img7"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg8.webp?alt=media&token=c6f9ea1b-c52d-4a76-8e89-6fb17ee8ab9c"
      alt="carousel img8"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg9.webp?alt=media&token=b9c1e758-c630-4768-ba03-0975ad4811bf"
      alt="carousel img9"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg9-5.jpeg?alt=media&token=ca636147-41b5-43f4-b793-442181c3b25f"
      alt="carousel img9"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg10.webp?alt=media&token=ae67f5d2-291e-4123-b634-4b8ac6069b35"
      alt="carousel img10"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg11.webp?alt=media&token=11b869d1-904f-441b-b6b6-bf9f52066ded"
      alt="carousel img11"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg12.webp?alt=media&token=da53934c-3b6c-46fe-9967-c1f4735e5448"
      alt="carousel img12"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg12-5.jpeg?alt=media&token=36854f42-7537-4b01-bcb3-77d5834ae0ba"
      alt="carousel img12"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg13.webp?alt=media&token=facdb4a1-aee2-4cc1-b0a9-de35c7d19d9f"
      alt="carousel img13"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg14.webp?alt=media&token=f82f2623-1135-4f85-b86e-00330b245162"
      alt="carousel img14"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg15.webp?alt=media&token=3a1a47bd-0013-4cf3-a358-5e9a97b0f4c4"
      alt="carousel img15"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg15-5.jpeg?alt=media&token=01709076-286d-4b55-aac0-ff5ed691749c"
      alt="carousel img15"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg16.webp?alt=media&token=fded4567-deaf-4459-82eb-e5d7371d7286"
      alt="carousel img16"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg17.webp?alt=media&token=58d248dc-4ced-4c95-a2dd-caf26b142ede"
      alt="carousel img17"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg18.webp?alt=media&token=91373733-0f98-4fcd-9f01-e57eab4c9546"
      alt="carousel img18"
    />,
    <Lazy
      image="https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Gallery%2FCarousel%2Fimg18-5.jpeg?alt=media&token=df6f69e5-f469-400b-b8ea-1fc4bc79d004"
      alt="carousel img18"
    />,
  ];
  return (
    <div className="threeD">
      <Carousel slides={images} autoplay={true} interval={3000} />
    </div>
  );
}
