import React from "react";
import Divider from "@material-ui/core/Divider";
import graphic from "../../assets/Eco Projects/Artboard 1.png";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

import news from "../../assets/Announcements/News SoFF 07.jpg";
import "./Announcements.scss";


import update1 from "../../assets/Announcements/update-4.jpg";
import update2 from "../../assets/Announcements/update-2.jpg";
import update3 from "../../assets/Announcements/update-3.jpg";
import update4 from "../../assets/Announcements/update4.jpg";

export default function Eco() {
  const pageContentStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'flex-start',
  };

  const leftStyle = {
    flex: 1,
    minWidth: '300px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '50px 20px',
  };

  const rightStyle = {
    flex: 1,
    minWidth: '300px',
    padding: '0 20px',
  };

  const imgStyle = {
    padding: '20px',
    maxWidth: '100%',
    height: 'auto',
    maxHeight: '500px',
    objectFit: 'contain',
  };

  const headingStyle = {
    fontSize: '2rem',
    marginBottom: '1rem',
    paddingtop:'20px',
    paddingleft:'30px',
    color:'yellow',
  };

  const paragraphStyle = {
    fontSize: '1.05rem',
    padding:'40px',
    textAlign: 'justify',
    color: 'white',
  };
  const paragraphStyle2 = {
    fontSize: '1.05rem',
    padding:'20px',
    textAlign: 'justify',
    color: 'white',
  };


  return (
    <div className="page internship-page">
      <Helmet>
        <title>DNALife | Updates</title>
        <meta name="description" />
        <meta
          name="keywords"
          content="DNALife, DNA Life, DNA Seeds Bags, Rain Water Harvesting and Recharging Ground Water, Establishment of a Nursery to Grow Local and Forest Plant Species, Conservation and Documentation of Medicinal Plants, Afforestation of Barren Rocky Land, Restoration of Rain Hills - Seegegudda or Malegudda and Nanjedevaragudda, Malali Gowda, Gaurav V, Divya"
        />
        <meta name="author" content="Gaurav V, Divya" />
      </Helmet>
    
      <div style={pageContentStyle}>
        <div style={leftStyle}>
          <img src={graphic} alt="cleaning" style={imgStyle} />
          <br>
          </br>
          <br></br>
          <br></br>
          <img src={news} alt="cleaning" style={imgStyle} />
         
        </div>
        <div style={rightStyle}>
          
            
        <h1 style={headingStyle}></h1>
          <p style={paragraphStyle}>
         
          </p>
          <p style ={paragraphStyle2}>
            <a
              href="https://www.livemint.com/science/news/dark-oxygen-found-in-deep-ocean-13-000-feet-below-sea-level-may-provide-insights-into-origin-of-life-11721722516550.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              
            </a>
          </p>
          <h1 style={headingStyle}>Exciting global news</h1>
          <p style={paragraphStyle}>
          Dark oxygen found in deep ocean 13,000 feet below sea level may provide insights into origin of life.
         <br></br>
            <a
              href="https://www.livemint.com/science/news/dark-oxygen-found-in-deep-ocean-13-000-feet-below-sea-level-may-provide-insights-into-origin-of-life-11721722516550.html"
              target="_blank"
              rel="noopener noreferrer" style={{color:"orange",}}
            >
              Click to View full news
            </a>
          </p>
          <h1 style={headingStyle}></h1>
          <p style={paragraphStyle}>
         
          </p>
          <p style ={paragraphStyle2}>
            <a
              href="https://www.livemint.com/science/news/dark-oxygen-found-in-deep-ocean-13-000-feet-below-sea-level-may-provide-insights-into-origin-of-life-11721722516550.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              
            </a>
          </p>
          <h1 style={headingStyle}></h1>
          <p style={paragraphStyle}>
         
          </p>
          <p style ={paragraphStyle2}>
            <a
              href="https://www.livemint.com/science/news/dark-oxygen-found-in-deep-ocean-13-000-feet-below-sea-level-may-provide-insights-into-origin-of-life-11721722516550.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              
            </a>
          </p>

          <h1 style={headingStyle}>School of Forest Farmers-07- Inauguration</h1>
          <p style={paragraphStyle}>
          On 1st of August 2024,  Govt high school students and teachers of Basavaghatta, Hassan Dist  Visited School of forest farmers 03. Prof B.D Kumar, Guru of school of forest farmers 03 gave the students farm tour end introduced children to different species of fruit trees and  other crops. He also explained the concept of organic farming, creating organic manure and rain water harvesting to the visiting students. After the educational tour of the farm, students attended the green event where Dr. Kariyanna Ph.D, Managing director of Hithkari Nursery, Bengaluru explained students about importance of growing quality food, fruits and urged the gathered students to take up agriculture as career. Later founder of DNA life organization Dr. Malali Gowda explained the students about forest farming school and motivated students to grow more and more trees near their house and thier farmland to make the world green.

All the students and teachers of Govt high school, Basavaghatta appreciated Prof B.D Kumar and his wife Indira Kumar for the green education and hospitality and  took oath to transform themselves as as green ambassadors and also inspire others to be sensitive towards nature
#DNAlife #forestfarming #SOFF #organicfarming #farming #agriculture #naturalfarming #nature #climatechange
<br></br>
<br></br>
            <a
              href="https://www.instagram.com/p/C-J9_hYShk9/?igsh=MW40OXM2ZTA2Z3VmdQ== "
              target="_blank"
              rel="noopener noreferrer" style={{color:"orange",}}
            >
              Click to View Instagram link
            </a>
          </p>
        </div>
      </div>
    </div>
  );
}
