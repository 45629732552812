import malali from "../../assets/Team/malali.jpg";
import gaurav from "../../assets/Team/gaurav.jpg";
import divya from "../../assets/Team/Divya.webp";
import fatima from "../../assets/Team/fatima.jpg";
import mahantesh from "../../assets/Team/mahantesh.webp";
import nagendra from "../../assets/Team/nagendra.webp";
import pruthvi from "../../assets/Team/pruthvi.jpg";
import raj from "../../assets/Team/raj.jpg";
import nuthan from "../../assets/Team/Nuthan.jpg";
import krish from "../../assets/Team/Krishnamurthy.jpg";
import vinuth from "../../assets/Team/vinuth(1)(1).png";
import kavya from "../../assets/Team/kavya.jpg";
import puttaraj from "../../assets/Team/puttaraj.jpg";
import mahima from "../../assets/Team/Mahima.jpg";
import muthu from "../../assets/Team/Muthuswamy.jpg";
import mittur from "../../assets/Team/Mittur.png";
import indira from "../../assets/Team/indira.jpg";
import ramalinga from "../../assets/Team/ramalinga1.png";
import indirapdf from "../../assets/Team/Indira-resume.pdf";
import puttarajresume from "../../assets/Team/puttaraj-resume.pdf";
import kavyaresume from "../../assets/Team/kavya-resume.pdf";
import maheshpdf from "../../assets/Team/Mahesh-resume.pdf";
import ram from "../../assets/Team/Ram Brief CV.pdf";
import Vinuthresume from "../../assets/Team/Vinuth-resume.pdf";

import mahesh from "../../assets/Team/mahesh.jpeg";
import tushar from "../../assets/Team/tushar.jpg";

export const Members = [
  {
    name: "Prof. Malali Gowda",
    link:
      "https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Profiles%2FBrief%20Profile%20of%20Malali%20Gowda%20-%20Environment.pdf?alt=media&token=41a46bed-786a-4658-a086-38a981857555",
    src: malali,
    position: "Founder & President, Former Trustee",
  },
  {
    name: "Mahesh kumar C S",
    link: maheshpdf,
    src: mahesh,
    position: "Vice President",
  },

  {
    name: "Dr. Tushar Joshi",
    src: tushar,
    position: "Secretary",
  },

  {
    name: "Mahantesh Veerapur",
    src: mahantesh,
    position: "Trustee",
  },
  { name: "Krishnamurthy", src: krish, position: "Trustee" },
  {
    name: "Vinuth",
    link: Vinuthresume,
    src: vinuth,
    position: "Trustee",
  },
  {
    name: "Kavya Lakshmikanth",
    link: kavyaresume,
    src: kavya,
    position: "Trustee",
  },
  {
    name: "Puttaraj",
    link: puttarajresume,
    src: puttaraj,
    position: "Trustee",
  },
  {
    name: "Indira Kumar",
    link: indirapdf,
    src: indira,
    position: "Trustee",
  },{
    name: "Ramalinga Reddy",
    link:ram,
    src: ramalinga,
    position: "Trustee",
  },
  {
    name: "Dr. Raj Rajaram",
    link:
      "https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Profiles%2FRaj-Resume_2020.pdf?alt=media&token=db34f5d2-90e5-4795-bf1d-ac36e20d099b",
    src: raj,
    position: "Advisor, USA",
  },
  {
    name: "Mahima J Patel",
    link:
      "https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Profiles%2FMahima%20J%20Patel.pdf?alt=media&token=4dcf0140-0b6d-4d3f-a761-8782fa5a21b3",
    src: mahima,
    position: "Advisor",
  },
  { name: "Dr. Mittur N Jagadish", src: mittur, position: "Advisor" },
  {
    name: "Sivaramakrishnan Muthuswamy",
    link:
      "https://firebasestorage.googleapis.com/v0/b/dnalife-website.appspot.com/o/Profiles%2FSivaramakrishnan%20Muthuswamy.pdf?alt=media&token=c2a84e90-13c5-4687-a7eb-44d15c9713e2",
    src: muthu,
    position: "Advisor",
  },
  
 
];

//{name: "", src: , position : ""},
